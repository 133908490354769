<template>
   <div class="detail-box">
      <el-table :data="refundTableParams" style="width: 100%" row-key="unionId" border
         :header-cell-style="{ background: '#F5F7FA' }" :key="1" v-loading="tableLoading">
         <!-- 商品名称 -->
         <el-table-column align="center" label="商品名称" prop="goodsName" :show-overflow-tooltip='true' width="150" />
         <!-- 商品原价 -->
         <el-table-column label="商品原价" prop="goodsAmount" align="center" :show-overflow-tooltip='true' width="150">
         </el-table-column>
         <!-- 支付积分 -->
         <el-table-column label="支付积分" prop="goodsPayIntegral" align="center" :show-overflow-tooltip='true' width="150">
         </el-table-column>
         <!-- 退款优惠 -->
         <el-table-column label="退款优惠" prop="discountAmount" align="center" :show-overflow-tooltip='true' width="150">
         </el-table-column>
         <!-- 退款积分 -->
         <el-table-column label="退款积分" prop="goodsRefundIntegral" align="center" :show-overflow-tooltip='true'
            width="150">
         </el-table-column>
         <!-- 退款数量 -->
         <el-table-column label="退款数量" prop="goodsRefundCount" align="center" :show-overflow-tooltip='true' width="150">
         </el-table-column>
         <!-- 交税比例 -->
         <el-table-column label="交税比例" prop="taxRate" align="center" :show-overflow-tooltip='true' width="150">
         </el-table-column>
         <!-- 税务编码 -->
         <el-table-column label="税务编码" prop="productionCode" align="center" :show-overflow-tooltip='true' width="150">
         </el-table-column>
         <!-- 状态 -->
         <el-table-column label="状态" align="center" width="150">
            <template #default="scope">
               <div v-if="scope.row.state == 1">部分退款</div>
               <div v-if="scope.row.state == 2">全额退款</div>
            </template>
         </el-table-column>
      </el-table>
   </div>
</template>

<script>
import { ref, onBeforeMount, onMounted } from 'vue';
import {
   xiaoxiangGoodsRefund
} from "../../utils/api";
import qs from 'qs';
export default {
   name: 'xiaoxiangrefundDetail',
   setup () {
      const tableLoading = ref(true); // 表格加载状态
      const refundTableParams = ref([]); // 表格数据
      // 获取订单详情数据
      const dataInfo1 = async () => {
         tableLoading.value = true;
         let data = {
            // 订单号
            sqtBizOrderId: sessionStorage.getItem('refundPageId'),
         }
         xiaoxiangGoodsRefund(qs.stringify(data)).then((res) => {
            let data = res.data;
            if (data.code == 0) {

               refundTableParams.value = data.data.xiaoXiangRefundGoodsList;
               tableLoading.value = false;
               console.log(tableLoading.value);

               console.log(refundTableParams.value,);

            }

         }).catch((error) => {
            // console.error('coinDetail 请求失败:', error);
            // ElMessage({
            //    message: '获取商品详情失败',
            //    type: "error",
            //    center: true,
            // });
         })
            .finally(() => {
               tableLoading.value = false; // 无论请求成功或失败，都隐藏加载状态
            });
      }
      // 生命周期钩子
      onBeforeMount(() => {

      });
      // 组件已挂载
      onMounted(() => {
         dataInfo1();
      });
      return {
         tableLoading,
         refundTableParams,
      };
   }
}
</script>

<style lang="scss" scoped>
.detail-box {
   height: calc(100vh - 120px);
   overflow: auto;
   background: #FFFFFF;
   padding: 20px;
}

::v-deep {
   .el-table tr {
      height: 54px;
   }
}
</style>